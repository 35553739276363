<template>
	<div class="notFoundView">
		
		<strong>NotFoundView.vue</strong><hr />

		<h1>404 for url "{{currentUrl}}"</h1>
		
	</div>
</template>

<script>
	// @ is an alias to /src
	import MhView from '@/components/MhView/MhView.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'NotFoundView',
		components: {
			MhView
		},
		props: {},
		data() {
			return {}
		},
		computed: {
			currentUrl(){
				return window.location
			}
		},
		methods: {},		
		mounted() {
			EventBus.$emit('stateChanged', 'loading')
			EventBus.$emit('stateChanged', 'loaded')
		}
	}
</script>

<style lang="less" scoped>	
	@import "../less/includes/grid.include.less";
	
	.notFoundView {
		padding: 1em;
	}
	
	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
